.testimonial-slide {
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: auto;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

@media (max-width: 768px) {
  .testimonial-slide {
    padding: 15px;
    max-width: 90%;
    font-size: 0.9rem; /* Yazı boyutunu küçült */
  }
}
